import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { authService, profileService } from 'services'

const initialState = {
  userId: null,
  name: null,
  limits: null,
  status: 'idle',
  error: null,
}

export const logIn = createAsyncThunk('profile/logIn', async (x, api) =>
  authService
    .logIn(x.username, x.password, x.persistent)
    .then(result => {
      if (result) return api.dispatch(fetchProfile())
      throw { message: 'Invalid email or password' }
    })
    .then(result => result && x.navigate()),
)
export const signUp = createAsyncThunk('profile/signUp', async (x, api) =>
  authService
    .signUp(x.invitation, x.email, x.password)
    .then(() => api.dispatch(fetchProfile()))
    .then(() => x.navigate()),
)
export const logOut = createAsyncThunk('profile/logOut', async () => authService.logOut())
export const fetchProfile = createAsyncThunk('profile/fetch', async () => profileService.getProfile())
export const updateProfile = createAsyncThunk('profile/update', async x => profileService.updateProfile(x))

const reset = (state, userId, name, limits, timeZoneId) => {
  state.userId = userId
  state.name = name
  state.limits = limits
  state.timeZoneId = timeZoneId
}

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(logOut.fulfilled, state => {
        state.status = 'succeeded'
        reset(state, null, null, null, null)
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { email, name, limits, timeZoneId } = action.payload
        reset(state, email, name, limits, timeZoneId)
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { name, limits, timeZoneId } = action.payload
        state.name = name
        state.limits = limits
        state.timeZoneId = timeZoneId
      })
      .addMatcher(isAnyOf(logIn.fulfilled, signUp.fulfilled), state => {
        state.status = 'succeeded'
      })
      .addMatcher(
        isAnyOf(logIn.pending, signUp.pending, logOut.pending, fetchProfile.pending, updateProfile.pending),
        state => {
          state.status = 'loading'
        },
      )
      .addMatcher(
        isAnyOf(logIn.rejected, signUp.rejected, logOut.rejected, fetchProfile.rejected, updateProfile.rejected),
        (state, action) => {
          state.status = 'failed'
          state.error = action.error
        },
      )
  },
})

export default slice.reducer

export const selectProfileStatus = state => state.profile.status

export const selectProfileError = state => state.profile.error

export const selectUserId = state => state.profile.userId

export const selectLimits = state => state.profile.limits

export const selectTimeZoneId = state => state.profile.timeZoneId
