import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import { referencesService } from 'services'
import { emptyObject, isEmpty } from 'utils/helpers'

const foodTypesAdapter = createEntityAdapter()
const timeZonesAdapter = createEntityAdapter()

const initialState = {
  foodTypes: foodTypesAdapter.getInitialState(),
  timeZones: timeZonesAdapter.getInitialState(),
  status: 'idle',
  error: null,
}

export const fetchReferences = createAsyncThunk('references/fetch', async () => {
  const foodTypes = await referencesService.getFoodTypes()
  const timeZones = await referencesService.getTimeZones()
  return [foodTypes, timeZones]
})

const slice = createSlice({
  name: 'references',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchReferences.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchReferences.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const [foodTypes, timeZones] = action.payload

        foodTypesAdapter.upsertMany(state.foodTypes, foodTypes)
        timeZonesAdapter.upsertMany(state.timeZones, timeZones)
      })
      .addCase(fetchReferences.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })
  },
})

export default slice.reducer

export const selectReferencesStatus = state => state.references.status

export const selectReferencesError = state => state.references.error

export const { selectAll: selectAllFoodTypes, selectById: selectFoodTypeById } = foodTypesAdapter.getSelectors(
  state => state.references.foodTypes,
)

export const selectFoodTypes = state => state.references.foodTypes.entities

export const selectMealType = createSelector([selectAllFoodTypes], foodTypes => {
  if (isEmpty(foodTypes)) return emptyObject

  return foodTypes.find(x => x.name === 'Meal')
})

export const { selectAll: selectAllTimeZones, selectById: selectTimeZoneById } = timeZonesAdapter.getSelectors(
  state => state.references.timeZones,
)

export const selectTimeZones = state => state.references.timeZones.entities
