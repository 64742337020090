import axios from 'axios'

const API_URL = '/api/food'

const getItems = async () => {
  const response = await axios.get(API_URL)
  return response.data
}

const addItem = async item => {
  const response = await axios.post(API_URL, item)
  return response.data
}

const updateItem = async item => {
  const response = await axios.put(API_URL, item)
  return response.data
}

const deleteItem = async id => {
  await axios.delete(`${API_URL}/${id}`)
  return id
}

const upload = async (file, type) => {
  const formData = new FormData()
  formData.append('type', type)
  formData.append('file', file)
  const response = await axios.post(`${API_URL}/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

const uploadImage = async (file, foodId) => {
  const formData = new FormData()
  formData.append('foodId', foodId)
  formData.append('file', file)
  const response = await axios.post(`${API_URL}/upload-image`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

const foodService = { addItem, deleteItem, getItems, updateItem, upload, uploadImage }

export default foodService
