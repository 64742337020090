import { Button } from 'components/core'
import PropTypes from 'prop-types'
import { useState } from 'react'

const AddButton = ({ onAdd = () => {}, onChange = () => {} }) => {
  const [state, setState] = useState('')
  const [tooltip, setTooltip] = useState('')

  const handleAdd = () => {
    if (!state) return
    onAdd(state)
    setState('')
  }

  const handleChange = value => {
    setState(value)
    if (value !== state) setTooltip(onChange(value))
  }

  return (
    <div className='field has-addons button-field'>
      <div className='control'>
        <div className={`tag has-background-info-light is-overlay floating-tag ${tooltip ? 'visible' : ''}`}>
          {tooltip}
        </div>
        <input
          className='input is-small'
          type='number'
          value={state}
          onChange={e => handleChange(+e.target.value)}
          onKeyUp={e => e.code === 'Enter' && handleAdd()}
        />
      </div>
      <Button className='is-small' icon='plus' onClick={handleAdd} />
    </div>
  )
}

AddButton.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onChange: PropTypes.func,
}

export default AddButton
