import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit'
import { LogIn, SignUp } from 'components/common'
import { settingsService } from 'services'
import { emptyObject, isEmpty } from 'utils/helpers'

const initialState = {
  loginMenu: [
    { id: 1, name: 'Log In' },
    { id: 2, name: 'Sign Up' },
  ],
  menu: [
    { id: 1, name: 'Diary', url: '/diary' },
    { id: 2, name: 'Food', url: '/food' },
    { id: 3, name: 'Meal', url: '/meal' },
    { id: 4, name: 'Profile', url: '/profile' },
  ],
  passwordPolicy: null,
  status: 'idle',
  error: null,
}

export const fetchSettings = createAsyncThunk('settings/fetch', async () => settingsService.getSettings())

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchSettings.pending, state => {
        state.status = 'loading'
      })
      .addCase(fetchSettings.fulfilled, (state, action) => {
        state.status = 'succeeded'
        const { version, passwordPolicy } = action.payload
        state.version = version
        state.passwordPolicy = passwordPolicy
      })
      .addCase(fetchSettings.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error
      })
  },
})

export default slice.reducer

export const selectSettings = state => state.settings
export const selectSettingsStatus = state => state.settings.status

export const selectLoginMenu = createSelector([selectSettings], settings => {
  if (isEmpty(settings)) return emptyObject

  const components = { 1: () => <LogIn />, 2: () => <SignUp /> }
  return settings.loginMenu.map(x => ({ ...x, render: components[x.id] }))
})

export const selectMenu = state => state.settings.menu

export const selectVersion = state => state.settings.version
export const selectPasswordPolicy = state => state.settings.passwordPolicy
