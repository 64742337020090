import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { foodService } from 'services'
import { emptyArray, hasEmpty } from 'utils/helpers'
import { selectFoodTypes, selectMealType } from './references.slice'

const foodAdapter = createEntityAdapter()

const initialState = foodAdapter.getInitialState({
  status: 'idle',
  error: null,
})

export const fetchFoodItems = createAsyncThunk('food/fetch', async () => await foodService.getItems())

export const addFoodItem = createAsyncThunk('food/add', async x => await foodService.addItem(x))

export const updateFoodItem = createAsyncThunk('food/update', async x => await foodService.updateItem(x))

export const deleteFoodItem = createAsyncThunk('food/delete', async id => await foodService.deleteItem(id))

export const uploadFoodItems = createAsyncThunk('food/upload', async ({ file, type }, api) =>
  foodService.upload(file, type).then(() => api.dispatch(fetchFoodItems())),
)

export const uploadFoodImage = createAsyncThunk('food/upload-image', async ({ file, foodId }) =>
  foodService.uploadImage(file, foodId),
)

const slice = createSlice({
  name: 'food',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchFoodItems.fulfilled, (state, action) => {
        state.status = 'succeeded'
        foodAdapter.upsertMany(state, action.payload)
      })
      .addCase(addFoodItem.fulfilled, (state, action) => {
        state.status = 'succeeded'
        foodAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateFoodItem.fulfilled, (state, action) => {
        state.status = 'succeeded'
        foodAdapter.upsertOne(state, action.payload)
      })
      .addCase(uploadFoodItems.fulfilled, state => {
        state.status = 'succeeded'
      })
      .addCase(deleteFoodItem.fulfilled, (state, action) => {
        state.status = 'succeeded'
        foodAdapter.removeOne(state, action.payload)
      })
      .addCase(uploadFoodImage.fulfilled, state => {
        state.status = 'succeeded'
      })
      .addMatcher(isAnyOf(fetchFoodItems.pending, deleteFoodItem.pending), state => {
        state.status = 'loading'
      })
      .addMatcher(
        isAnyOf(addFoodItem.pending, updateFoodItem.pending, uploadFoodItems.pending, uploadFoodImage.pending),
        state => {
          state.status = 'loading-modal'
        },
      )
      .addMatcher(
        isAnyOf(
          fetchFoodItems.rejected,
          addFoodItem.rejected,
          updateFoodItem.rejected,
          deleteFoodItem.rejected,
          uploadFoodItems.rejected,
          uploadFoodImage.rejected,
        ),
        (state, action) => {
          state.status = 'failed'
          state.error = action.error
        },
      )
  },
})

export default slice.reducer

export const selectFoodStatus = state => state.food.status

export const selectFoodError = state => state.food.error

export const {
  selectAll: selectAllFood,
  selectById: selectFoodById,
  selectIds: selectFoodIds,
} = foodAdapter.getSelectors(state => state.food)

export const selectFood = state => state.food.entities

export const selectFoodInfo = createSelector([selectFoodTypes, selectAllFood], (foodTypes, food) => {
  if (hasEmpty(foodTypes, food)) return emptyArray

  return food.map(x => {
    return {
      ...x,
      nameLower: x.name?.toLowerCase(),
      aliasLower: x.alias?.toLowerCase(),
      createdDate: new Date(x.created),
    }
  })
})

export const selectMealInfo = createSelector(
  [selectFoodInfo, selectMealType, selectFood],
  (foodInfo, mealType, food) => {
    if (hasEmpty(foodInfo, mealType, food)) return emptyArray

    return foodInfo
      .filter(x => x.typeId === mealType.id)
      .map(x => {
        return {
          ...x,
          parts: x.parts.map(p => {
            const foodItem = food[p.foodItemId]
            return {
              ...p,
              name: foodItem?.name,
              alias: foodItem?.alias,
              calories: foodItem?.calories,
              protein: foodItem?.protein,
              fat: foodItem?.fat,
              carbohydrate: foodItem?.carbohydrate,
            }
          }),
        }
      })
  },
)
