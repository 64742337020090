export const calculateCalories = (protein, fat, carbohydrate) =>
  Math.round((protein ?? 0) * 4 + (fat ?? 0) * 9 + (carbohydrate ?? 0) * 3.75)

export const calculateNutritionDetails = (foodItem, weight) => {
  const calories = Math.round((weight * foodItem?.calories) / 100)
  const protein = Math.round((weight * foodItem?.protein) / 100)
  const fat = Math.round((weight * foodItem?.fat) / 100)
  const carbohydrate = Math.round((weight * foodItem?.carbohydrate) / 100)
  return { calories, protein, fat, carbohydrate }
}
