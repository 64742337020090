import PropTypes from 'prop-types'
import { useCallback, useEffect, useMemo, useState } from 'react'

const Datagrid = ({
  model = [],
  config = [],
  getId = x => x.id,
  totalCount,
  noDataMessage = 'No data',
  onRowClick,
}) => {
  const [items, setItems] = useState([])

  const defaultSortingItem = useMemo(() => config.find(x => x.sort && x.default) || config.find(x => x.sort), [config])

  const [sorting, setSorting] = useState({
    code: defaultSortingItem?.id,
    ascModifier: 1,
    getField: defaultSortingItem?.sort,
  })

  const sort = useCallback(
    items => {
      items.sort((a, b) => (sorting.getField(a) > sorting.getField(b) ? sorting.ascModifier : -1 * sorting.ascModifier))
      return items
    },
    [sorting],
  )

  const onSort = (code, getField) => {
    const ascModifier = code === sorting.code ? sorting.ascModifier * -1 : 1
    setSorting({ code: code, ascModifier, getField })
  }

  useEffect(() => {
    setItems(sort([...model]))
  }, [model, sort])

  const composeSortingClass = useCallback(
    code => 'sortable ' + (sorting.code === code ? (sorting.ascModifier === 1 ? 'sort-asc' : 'sort-desc') : ''),
    [sorting.ascModifier, sorting.code],
  )

  const getTotal = () => (totalCount ? totalCount : items.length)
  const getFooterText = () => (items.length ? `${items.length} of ${getTotal()}` : noDataMessage)

  return (
    <table className='table is-fullwidth'>
      <thead>
        <tr>
          {config
            .filter(x => x.enabled)
            .map(x => (
              <th
                key={x.id}
                className={x.type + (x.sort ? ' ' + composeSortingClass(x.id) : '')}
                onClick={x.sort ? () => onSort(x.id, x.sort) : undefined}>
                <abbr title={x.desc || x.title}>{x.title}</abbr>
              </th>
            ))}
        </tr>
      </thead>
      <tbody>
        {items.map(x => (
          <tr
            key={getId(x)}
            className={`${onRowClick ? 'row-button' : ''}`}
            onClick={() => onRowClick && onRowClick(x)}>
            {config
              .filter(c => c.enabled)
              .map(c => (
                <td key={c.id} className={`${c.type}`}>
                  {c.content(x)}
                </td>
              ))}
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td className={!items.length ? 'no-data' : ''} colSpan={config.filter(c => c.enabled).length}>
            {getFooterText()}
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

Datagrid.propTypes = {
  model: PropTypes.array.isRequired,
  config: PropTypes.array.isRequired,
  getId: PropTypes.func,
  noDataMessage: PropTypes.string,
  totalCount: PropTypes.number,
  onRowClick: PropTypes.func,
}

export default Datagrid
